import React from 'react';
import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import CvPage from './pages/CvPage';
import ProjetsPage from './pages/ProjetsPage';
import KontakPage from './pages/KontaktPage';

function App() {
  const styles = {
    appContainer: {
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f5f5f5',
    },
  };

  return (
    <Router>
      <div style={styles.appContainer}>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/Cv" element={<CvPage/>} />
          <Route path='Projets' element = {<ProjetsPage/>}/>
          <Route path='Contact' element = {<KontakPage/>}/>
        </Routes>
      </div>
      
    </Router>
    
  );
}

export default App;
