import React from 'react';
import './AboutMyself.css'; 
import { useNavigate } from 'react-router-dom';


const AboutMyself = ({ onPress }) => {

  const title1 = "Hallo";
  const title2 = "Über mich";
  const navigate = useNavigate();

  const handleCvClick = () => {
    navigate('/Cv');
  };
  const handleProjetsClick = () => {
    navigate('/Projets');
  };

  const handleContactClick = () => {
    navigate('/Contact');
  };

  return (
    <div className='container-aboutMyself'>
        <div className="container">
        <div className="intro">
          <div className="containerProfil">
            <img 
              src={require('../assets/lebenslauf_Bild.jpg')} 
              alt="Profil" 
              className="circleImage" 
            />
          </div>

          <div className="paragraph">

              <h1 className="titre1">{title1}</h1>
              <h2 className="titre2">{title2}</h2>
            
            <div className="texte">
              <p>Engagierter B. Eng in Informatik mit fundierten Kenntnissen</p>
              <p>in der Entwicklung nativer und Desktop-Anwendungen in Java,</p>
              <p> und C# sowie Erfahrung in der Datenbankentwicklung.</p>
            </div>
            

            <div className="innerContainer">
              <button className="circleButton cv" onClick={handleCvClick}>CvPage</button>
              <button className="circleButton projets" onClick={handleProjetsClick}>Projekte</button>
              <button className="circleButton contacts" onClick={handleContactClick}>Kontakt</button>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  );
};

export default AboutMyself;
