import './MyProjets.css'

const MyProjets = () => {
    return(
        <div className="container-my-projets">
            <div className='projet-inhalt'>

                <div className='projet-title'>
                    <h1 >Meine Projekte</h1>
                </div>
                
                <div className='bloc-projets'>
                    <div className='bloc-projet'>
                        <h2>3d Konfigurator</h2>
                        <ul className='espace-bloc'>
                            <li >
                                Entwicklung von Scripts zur Steuerung von Animationen, 
                                z. B. durch Benutzerinteraktionen oder physikalische Bedingungen
                            </li>
                            <li>
                                Verwendung von Unity-APIs
                            </li>
                            <li>
                                Implementierung von Animations-Übergängen 
                                und State Controller mithilfe von C# -Code.
                            </li>
                        </ul>
                        <p>
                            <strong>Ziel:</strong> Das Ziel der Fahrradkonfigurations-App ist es, eine einfache und intuitive Möglichkeit zu bieten, das Fahrrad an die eigenen Bedürfnisse anzupassen. Die App ermöglicht es, 
                            verschiedene Komponenten wie Rahmen, Reifen, Schaltung und Zubehör auszuwählen und in Echtzeit eine visuelle Vorschau des konfigurierten Fahrrads anzuzeigen. Darüber hinaus liefert sie nützliche Informationen zu den ausgewählten Teilen, 
                            Preisübersichten und technische Spezifikationen. 
                        </p>
                    </div>

                    <div>
                        <img 
                            src={require('../assets/Fahrrad.jpg')} 
                            alt="solar" 
                            className="solarEnergyImage" 
                        />
                    </div>
                </div>
                <div className='bloc-projets'>
                    <div className='bloc-projet'>
                        <h2>Automatisches Bewässerungssystem mit dem MQTT-Protokoll</h2>
                        <ul className='espace-bloc'>
                            <li >
                                Untersuchung von Methoden zur Verbesserung der Geschwindigkeit des MQTT-Protokolls
                            </li>
                            <li>
                                Überwachung von Luftfeuchtigkeit und Wasserstand 
                                in einem Blumentopf mit einem Raspberry Pi als Brocker
                            </li>
                        </ul>
                        <p>
                            <strong>Ziel:</strong> Die Feuchtigkeit und Temperatur der Gläser, in denen die Blumentöpfe stehen, werden mithilfe von Sensoren kontinuierlich überwacht. Sobald die erfassten 
                            Bedingungen nicht mehr den optimalen Anforderungen einer bestimmten Pflanze entsprechen, aktiviert ein automatisches Bewässerungssystem eine Pumpe, um die Pflanze zu versorgen. Im Rahmen dieses Projekts 
                            wurde zudem untersucht, wie die Effizienz des MQTT-Protokolls weiter verbessert werden kann, um eine schnellere und zuverlässigere Datenübertragung zu gewährleisten.
                        </p>
                    </div>

                    <div>
                        <img 
                            src={require('../assets/mqtt.jpg')} 
                            alt="solar" 
                            className="solarEnergyImage" 
                        />
                    </div>
                </div>
                <div className='bloc-projets'>
                    <div className='bloc-projet'>
                        <h2>kundenverwaltung App .Net framework</h2>
                        <ul className='espace-bloc'>
                            <li >
                                Erstellung von Desktop App mit Blazor Hyrid
                            </li>
                            <li>
                                SQL Datenbankverwaltung um Artikel zu pflegen
                            </li>
                            <li>
                                Anpassung der Benutzeroberfläche und der Formatierung an die Bedürfnisse
                            </li>
                        </ul>
                        <p>
                            <strong>Ziel:</strong> Entwicklung einer App zur Verwaltung von Kundenprodukten, 
                            basierend auf einer SQL-Datenbank. Die App wurde mit Blazor Hybrid implementiert,
                            wodurch moderne Windows-Desktop-Anwendungen unter Verwendung von HTML und CSS erstellt
                            werden konnten.
                        </p>
                    </div>

                    <div>
                        <img 
                            src={require('../assets/blazor.png')} 
                            alt="solar" 
                            className="solarEnergyImage" 
                        />
                    </div>
                </div>

                <div className='bloc-projets'>
                    <div className='bloc-projet'>
                        <h2>Netzwerksystem</h2>
                        <ul className='espace-bloc'>
                            <li >
                                Analyse der Anforderungen und Definition eines Netzwerkadressschemas 
                                (z. B. private IP-Bereiche).
                            </li>
                            <li>
                                Auswahl und Einrichtung von Routern, Switches, Access Points und Servern.
                            </li>
                            <li>
                                Implementierung von Firewall-Regeln, 
                                Netzwerkauthentifizierung und Verschlüsselung (WPA3).
                            </li>
                        </ul>
                        <p>
                            <strong>Ziel:</strong> Aufbau eines sicheren und effizienten privaten Netzwerks
                             für interne Kommunikation und Datenverwaltung, inklusive der Einrichtung von 
                             Zugangskontrollen und Segmentierung der Netzwerkressourcen.
                        </p>
                    </div>

                    <div>
                        <img 
                            src={require('../assets/network_dose.jpg')} 
                            alt="solar" 
                            className="solarEnergyImage" 
                        />
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default MyProjets;