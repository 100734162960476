
import React from 'react';
import MyComponent from '../components/MyComponent';
import FooterContact from '../components/FooterContact';
import Kontakt from '../components/Kontakt';


const KontakPage = () =>{
    return(
        <div>
            <MyComponent/>
            <Kontakt/>
            <FooterContact/>
        </div>
    );
};

export default KontakPage;