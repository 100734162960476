import FooterContact from "../components/FooterContact";
import MyComponent from "../components/MyComponent";
import MyProjets from "../components/MyProjets"

const ProjetsPage = () => {
    return(
        <div>
            <MyComponent/>
            <MyProjets/>
            <FooterContact/>
        </div>
    );
}

export default ProjetsPage;